
import { Fragment } from "vue-frag";
export default {
  components: {
    Fragment,
  },
  data() {
    return {
      openMenu: false,
      categoryOpen: false,
      mega_menu: this.prepareMega(),
      active: {},
      active_title: "",
      active_level: [],
      active_promos: [],
      active_title_nested: "",
      active_level_nested: [],
      active_nested: {},
    };
  },
  watch: {
    $route: function (v, o) {
      if (v.path != o.path) {
        this.openCart = false;
        this.openMenu = false;
        this.active_title = "";
        this.active = {};
        this.active_level = [];
        this.active_promos = [];
        this.active_title_nested = "";
        this.active_nested = {};
        this.active_level_nested = [];
        document.querySelector("body").classList.remove("overflow-hidden");
        this.$nuxt.$emit('closeMenu');
      }
    },
    openMenu: function (v) {
      if (v) {
        document.querySelector("body").classList.add("overflow-hidden");
      } else {
        document.querySelector("body").classList.remove("overflow-hidden");
      }
    },
  },
  mounted() {
    $nuxt.$on("closeMenu", () => {
      this.openMenu = false;
    });
    $nuxt.$on("openMenu", () => {
      this.openMenu = true;
    });
  },
  methods: {
    level(b, nest) {
      if (b.children && b.children.length && !nest) {
        this.active = b;
        this.active_level = b.children;
        this.active_title = b.title;
        this.active_promos = b.promos;
        document.querySelector("#mobile_dynamic_mega_wrapper").scrollTo(0, 0);
      } else if (b.children && b.children.length && nest) {
        this.active_nested = b;
        this.active_level_nested = b.children;
        this.active_title_nested = b.title;
        document.querySelector("#mobile_dynamic_mega_wrapper").scrollTo(0, 0);
      }
    },
    prepareMega() {
      let temp = JSON.parse(
        JSON.stringify(this.$store.getters.store.eshop.mega_menu)
      );
      temp = temp.map((c) => {
        let full = false;
        c.children.forEach((cc) => {
          if (cc.children && cc.children.length) {
            full = true;
          }
        });
        c.full = full;
        return c;
      });
      return temp;
    },
  },
};
