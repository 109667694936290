import { render, staticRenderFns } from "./mobile_mega.vue?vue&type=template&id=ad31f402"
import script from "./mobile_mega.vue?vue&type=script&lang=js"
export * from "./mobile_mega.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HyperLink: require('/root/containers/hermes_eshop/components/core/hyperLink.vue').default})
