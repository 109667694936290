import { render, staticRenderFns } from "./mega_menu_dynamic.vue?vue&type=template&id=13d87056"
import script from "./mega_menu_dynamic.vue?vue&type=script&lang=js"
export * from "./mega_menu_dynamic.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Search: require('/root/containers/hermes_eshop/components/core/search.vue').default})
